import { VideoProvider } from '@/enums/videoProvider.enum';
import urlParser from 'js-video-url-parser/lib/base';
import 'js-video-url-parser/lib/provider/youtube';

export interface IVideoService {
    search: (options: IVideoSearchOptions) => Promise<IVideoSearchResponse>;
    find: (id: string) => Promise<IVideo | null>;
    findMany: (ids: string[]) => Promise<IVideo[]>;
}

export interface IVideo {
    url: string;
    image: string;
    title: string;
    channelTitle: string;
    publishedDate: string;
    duration: string;
    viewCount: string;
    provider: string;
}

export interface IVideoSearchOptions {
    query: string;
    pageToken?: string;
    limit?: number;
    sortOrder?: 'relevance' | 'date' | 'viewCount';
}

export interface IVideoSearchResponse {
    items: IVideo[];
    nextPageToken?: string;
}

export interface IVideoUrlInfo {
    provider: string;
    id: string;
}

export class VideoService {
    constructor(private readonly videoService: IVideoService) {}

    public search(options: IVideoSearchOptions): Promise<IVideoSearchResponse> {
        return this.videoService.search(options);
    }

    public find(id: string | string[]): Promise<IVideo | IVideo[] | null> {
        if (Array.isArray(id)) {
            return this.findMany(id);
        }
        return this.videoService.find(id);
    }

    public findMany(ids: string[]): Promise<IVideo[]> {
        return this.videoService.findMany(ids);
    }

    public static parseUrl(url: string): IVideoUrlInfo | null {
        const urlInfo = urlParser.parse(url);
        if (!urlInfo) {
            return null;
        }
        return {
            id: urlInfo.id,
            provider: urlInfo.provider as VideoProvider,
        };
    }
}
