<template>
    <div>
        <div ref="grid" class="grid">
            <div
                v-for="(item, idx) in items"
                :key="idx"
                class="grid-item"
                :class="{ 'grid-item--selected': selectedIdx === idx }"
                @click="onClick(idx)"
            >
                <Icon class="grid-item-selected-icon" name="file-upload-success" :size="24" />

                <slot :item="item" />
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'MasonryGrid',
    components: {
        Icon,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selectedIdx: null,
        };
    },
    methods: {
        onClick(index) {
            this.selectedIdx = index;
            this.$emit('input', this.items[index]);
        },
    },
};
</script>

<style scoped lang="scss">
.grid {
    columns: 6 200px;
    column-gap: 1rem;
}

.grid-item {
    position: relative;
    margin-bottom: 1rem;
    cursor: pointer;

    &--selected {
        border: 5px solid $color-primary;
    }
}

.grid-item-selected-icon {
    opacity: 0;
    transition: opacity 100ms ease-out;
    position: absolute;
    top: 8px;
    left: 8px;
    color: $color-light;

    .grid-item--selected & {
        opacity: 1;
    }
}
</style>
