<template>
    <FormOverlay
        :title="title"
        :confirm-button-text="confirmButtonText"
        :confirm-button-disabled="disableConfirmButton"
        :show-overlay="show"
        size="wide"
        @close="onClose"
        @confirm="onConfirm"
    >
        <form ref="form" class="m-b-4" @submit.prevent="onSubmit">
            <Search
                label="Search"
                placeholder="Enter keyword to search for a gif"
                :is-loading="isLoading"
            />
        </form>

        <div v-if="isLoading" class="gif-search-loading">
            <Spinner />
        </div>

        <MasonryGrid :items="images" @input="onSelected">
            <template v-slot="{ item }">
                <img :src="item.images.small.url" :alt="item.title" class="gif-preview" />
            </template>
        </MasonryGrid>

        <TextButton v-show="images.length !== 0" class="load-more" @click="onLoadMore">
            load more
        </TextButton>
    </FormOverlay>
</template>

<script>
import FormOverlay from '@/components/ui/FormOverlay';
import Spinner from '@/components/ui/Spinner';
import { Search } from '@/components/forms';
import MasonryGrid from '@/components/MasonryGrid';
import { GiphyService } from '@/services';
import { GiphyClient } from '@/api/giphy';
import TextButton from '@/components/ui/TextButton';

const defaultState = () => ({
    images: [],
    isLoading: false,
    selectedGif: undefined,
    query: '',
});

export default {
    name: 'GifSelector',
    components: {
        TextButton,
        FormOverlay,
        Search,
        MasonryGrid,
        Spinner,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: 'GIPHY Search',
        },
        confirmButtonText: {
            type: String,
            default: 'Select GIPHY',
        },
    },
    data: () => defaultState(),
    computed: {
        disableConfirmButton() {
            return this.selectedGif === undefined;
        },
    },
    beforeDestroy() {
        this.service = null;
        delete this.service;
    },
    created() {
        this.service = new GiphyService(new GiphyClient());
    },
    methods: {
        async onSubmit() {
            this.isLoading = true;
            const formData = new FormData(this.$refs.form);
            this.query = formData.get('query');
            this.search(this.query);
            this.isLoading = false;
        },
        async search(query, offset = 0) {
            if (!this.service) {
                this.$log.warn('No gif service available');
            }
            if (offset === 0) {
                this.images = [];
            }
            const result = await this.service.search(query, { offset });
            if (result.items) {
                this.images.push(...result.items);
            }
        },
        onLoadMore() {
            this.search(this.query, this.images.length);
        },
        onConfirm() {
            this.$emit('input', this.selectedGif);
            this.onClose();
        },
        onSelected(gif) {
            this.selectedGif = gif;
        },
        onClose() {
            this.resetState();
            this.$emit('close');
        },
        resetState() {
            Object.assign(this.$data, defaultState());
        },
    },
};
</script>
<style lang="scss">
.load-more {
    margin: 2rem auto;
}

.gif-preview {
    width: 100%;
    height: auto;
}
</style>
