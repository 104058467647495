import { IGifClient, IGifSearchOptions } from '@/services/giphyService';
import axios, { AxiosInstance } from 'axios';

const GIPHY_API_BASE_URL = 'https://api.giphy.com/v1/gifs';

export class GiphyClient implements IGifClient {
    httpClient: AxiosInstance;
    apiKey: string;

    constructor() {
        const httpClient = axios.create({
            baseURL: GIPHY_API_BASE_URL,
            params: {
                api_key: process.env.VUE_APP_GIPHY_API_KEY || '',
            },
        });
        httpClient.interceptors.response.use(
            (res) => res.data,
            (err) => {
                throw new Error('Giphy API request failed');
            },
        );
        this.httpClient = httpClient;
    }

    search<GiphyResult>(query: string, options: IGifSearchOptions): Promise<GiphyResult> {
        return this.httpClient.request({
            url: '/search',
            method: 'GET',
            params: {
                q: query,
                limit: options.limit || 20,
                offset: options.offset || 0,
            },
        });
    }
}
