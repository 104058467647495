interface IDuration {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export function convertISO8601ToObject(duration: string): IDuration {
    const timeExtractor = /^P([0-9]*D)?T([0-9]*H)?([0-9]*M)?([0-9]*S)?$/i;
    const extracted = timeExtractor.exec(duration);
    return {
        days: extracted && extracted[1] ? parseInt(extracted[1], 10) : 0,
        hours: extracted && extracted[2] ? parseInt(extracted[2], 10) : 0,
        minutes: extracted && extracted[3] ? parseInt(extracted[3], 10) : 0,
        seconds: extracted && extracted[4] ? parseInt(extracted[4], 10) : 0,
    };
}

export function convertDurationToString(duration: IDuration, delimiter: string = ':'): string {
    const output = [];
    duration.days > 0 && output.push(duration.days);
    duration.hours > 0 && output.push(duration.hours);
    output.push(duration.hours > 0 ? prependZeroToNumber(duration.minutes) : duration.minutes);
    output.push(prependZeroToNumber(duration.seconds));
    return output.join(delimiter);
}

function prependZeroToNumber(number: number): string {
    return ('0' + number).slice(-2);
}
