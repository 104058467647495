import { ISearchItemResponse, ISearchResponse } from '@/api/interfaces/youtube.interface';
import { convertDurationToString, convertISO8601ToObject } from '@/utils/time';
import { IVideo, IVideoSearchOptions, IVideoSearchResponse, IVideoService } from './videoService';
import { abbreviateNumber } from '@/utils/abbreviateNumber';
import { VideoProvider } from '@/enums/videoProvider.enum';
import urlParser from 'js-video-url-parser/lib/base';
import { IYouTubeClient } from '@/api/youtube';
import { YouTubeClient } from 'youtube-client';

export class YouTubeVideoService implements IVideoService {
    client: IYouTubeClient;

    constructor() {
        this.client = new YouTubeClient();
    }

    async search(options: IVideoSearchOptions): Promise<IVideoSearchResponse> {
        try {
            const results: ISearchResponse = await this.client.search(options);
            const videoIds = (results?.items || []).map((item) => item.id.videoId);
            const items = await this.findMany(videoIds);
            const response = <IVideoSearchResponse>{ items };
            if (results.nextPageToken) {
                response.nextPageToken = results.nextPageToken;
            }
            return response;
        } catch (error) {
            return { items: [] };
        }
    }

    async find(id: string): Promise<IVideo | null> {
        const results: ISearchResponse = await this.client.searchVideos(id);
        const video = (results?.items || []).find((item) => item.id === id);
        if (!video) {
            return null;
        }
        return this.mapVideoData(video);
    }

    async findMany(ids: string[]): Promise<IVideo[]> {
        const results = await this.client.searchVideos(ids.join(','));
        return (results?.items || [])
            .filter((video) => ids.includes(video.id))
            .map(this.mapVideoData.bind(this));
    }

    private mapVideoData(data: ISearchItemResponse): IVideo {
        const url = urlParser.create({
            videoInfo: { provider: 'youtube', id: data.id, mediaType: 'video' },
            format: 'embed',
        });

        return {
            url: url!,
            image: data.snippet.thumbnails.high.url,
            title: data.snippet.title,
            channelTitle: data.snippet.channelTitle,
            publishedDate: data.snippet.publishedAt,
            duration: this.getVideoDuration(data.contentDetails?.duration || ''),
            viewCount: abbreviateNumber(
                data.statistics?.viewCount ? parseInt(data.statistics.viewCount, 10) : 0,
            ),
            provider: VideoProvider.YouTube,
        };
    }

    private getVideoDuration(duration: string): string {
        return convertDurationToString(convertISO8601ToObject(duration));
    }
}
