import { ISearchResponse } from './interfaces/youtube.interface';
import axios, { AxiosInstance } from 'axios';
import Vue from 'vue';
import { IVideoSearchOptions } from '@/services/videoService';

const YOUTUBE_API_BASE_URL = 'https://www.googleapis.com/youtube/v3';
const YOUTUBE_API_KEY = process.env.VUE_APP_YOUTUBE_API_KEY || '';

export interface IYouTubeClient {
    search: (query: IVideoSearchOptions) => Promise<ISearchResponse>;
    searchVideos: (videoIds: string) => Promise<ISearchResponse>;
}

export class YouTubeClient implements IYouTubeClient {
    httpClient: AxiosInstance;
    apiKey: string;

    constructor() {
        const httpClient = axios.create({ baseURL: YOUTUBE_API_BASE_URL });
        httpClient.interceptors.response.use(
            (res) => res.data,
            (err) => {
                Vue.$log.error('response', err);
                Vue.prototype.$toaster.add('YouTube API request failed');
                throw new Error('YouTube API request failed');
            },
        );
        this.httpClient = httpClient;
        this.apiKey = YOUTUBE_API_KEY;
    }

    public search(options: IVideoSearchOptions): Promise<ISearchResponse> {
        return this.httpClient.request({
            url: '/search',
            params: {
                key: this.apiKey,
                part: 'snippet',
                type: 'video',
                eventType: 'completed',
                order: options.sortOrder || 'relevance',
                maxResults: options.limit || 12,
                pageToken: options.pageToken || null,
                q: options.query,
            },
        });
    }

    public searchVideos(videoIds: string): Promise<ISearchResponse> {
        return this.httpClient.request({
            url: '/videos',
            params: {
                key: this.apiKey,
                part: 'snippet,contentDetails,statistics',
                id: videoIds,
            },
        });
    }
}
