import { IGif } from '@/store/models/interfaces';
import { IGif as IGiphy } from '@giphy/js-types';
import { GifsResult as GiphyResult } from '@giphy/js-fetch-api';

export interface IGifSearchResult {
    items: IGif[];
}

export interface IGifSearchOptions {
    offset?: number;
    limit?: number;
}

export interface IGifClient {
    search<T>(query: string, options?: IGifSearchOptions): Promise<T>;
}

export class GiphyService {
    constructor(private readonly client: IGifClient) {}

    async search(query: string, options: IGifSearchOptions): Promise<IGifSearchResult> {
        const result = await this.client.search<GiphyResult>(query, options);

        const response = {
            items: [] as IGif[],
        };

        if (!result?.data) {
            return response;
        }

        response.items = result.data.map(this.mapData);

        return response;
    }

    private mapData(giphy: IGiphy): IGif {
        return {
            images: {
                small: {
                    url: giphy.images.fixed_width.url,
                    width: giphy.images.fixed_width.width,
                    height: giphy.images.fixed_width.height,
                },
                original: {
                    url: giphy.images.original.url,
                    width: giphy.images.original.width,
                    height: giphy.images.original.height,
                },
            },
            url: giphy.bitly_url,
            title: giphy.title,
        };
    }
}
